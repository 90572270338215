import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "name": "Benfleet",
  "priceBand": 1,
  "title": "Benfleet",
  "description": "todo\n"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Planning a children’s party in Benfleet, Essex`}</h1>
    <p><a parentName="p" {...{
        "href": "https://www.essex.gov.uk/Education-Schools/Schools/Dates/Pages/Term-Times-Holidays.aspx",
        "target": "_blank",
        "rel": "nofollow noopener noreferrer"
      }}>{`https://www.essex.gov.uk/Education-Schools/Schools/Dates/Pages/Term-Times-Holidays.aspx`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      